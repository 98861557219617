<template>
  <div class="bookingStatus">
    <div class="steps"></div>
    <div class="status" v-if="bookingData.status == 0">
      <img src="@/assets/img/status_wait.png">
      <p>审核中</p>
      <h3>请保持电话畅通<br>工作人员将会与您沟通具体时间<br><br><a class="blue" href="tel:+86 18657980270">联系电话：18657980270</a></h3>
    </div>

    <!-- <div class="status" v-else-if="status == 1">
      <img src="@/assets/img/status_success.png">
      <p>预约成功!</p>
      <h3>感谢您的参与<br>有任何问题请联系：<br><a class="blue" href="tel:+86 18657980270">陈老师 18657980270</a></h3>
      <router-link to="/card" class="btn">查看体验卡</router-link>
    </div> -->

    <div class="status" v-else-if="bookingData.status == 2">
      <img src="@/assets/img/status_error.png">
      <p>预约失败</p>
      <h3>{{bookingData.remark}}<br><a class="blue" href="tel:+86 18657980270">联系电话：18657980270</a></h3>
    </div>
  </div>
</template>
<script>
import { scheduleDetail } from "@/api/getData"
export default {
  name: "BookingStatus",
  data() {
    return {
      bookingData: {}
    }
  },
  methods: {
    statusSuccess(status, scheduleId) {
      if (status == 1) {
        this.$router.push({
          path: "/card",
          query: {
            scheduleId: scheduleId
          }
        })
      }else if(status == 3){
        this.$router.push("/my")
      }
    },
    getStatus() {
      scheduleDetail(this.$route.query.scheduleId).then(res => {
        this.bookingData = res.data

        var userInfo = {}
        userInfo.name = res.data.name
        userInfo.mobile = res.data.mobile
        userInfo.birthday = res.data.birthday
        userInfo.gender = res.data.gender
        this.$store.commit('SET_vUserInfo', userInfo)

        this.statusSuccess(res.data.status, res.data.scheduleId)
      })
    }
  },
  mounted() {
    this.getStatus()
  }
}
</script>

<style lang="scss" scoped>
.bookingStatus {
  .steps {
    height: 0.06rem;
    background: #24bbd3;
  }
  .status {
    text-align: center;
    img {
      display: block;
      margin: 1rem auto 0.24rem;
      width: 1.12rem;
    }
    p {
      font-size: 0.14rem;
      line-height: 0.24rem;
      margin-bottom: 0.24rem;
      font-weight: 700;
    }
    h3 {
      font-size: 0.18rem;
      line-height: 0.32rem;
    }
    .btn {
      display: block;
      margin: 1.16rem 0.16rem 0.32rem;
      background: #24bbd3;
      border-color: #24bbd3;
      font-size: 0.18rem;
      height: 0.44rem;
      line-height: 0.44rem;
      color: #fff;
    }
  }
}
</style>